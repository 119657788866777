


























































































































































































































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import ArchiveButton from "@/components/Button/ArchiveButton.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import { dataURLtoFile, getFile } from "@/utils/File";
import TrainingContractRequestMixin from "@/mixins/Request/TrainingContractRequestMixin";
import { mixins } from "vue-class-component";
import SignTrainingContractModal from "@/views/Student/Info/SignTrainingContractModal.vue";
import SignTrainingContract from "@/views/Student/Info/SignTrainingContract.vue";
import moment from "moment";
import { namespace } from "vuex-class";
import { v4 as uuidv4 } from "uuid";
import QrcodeButton from "@/components/Button/QrcodeButton.vue";
import FscModal from "@/components/Modal/FscModal.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import { ICompletedDTO } from "@/interfaces/ICompletedDTO";
import { IPlannedLessonsDTO } from "@/interfaces/IPlannedLessonsDTO";
import { IRequirementsDTO } from "@/interfaces/IRequirementsDTO";
import SignatureButton from "@/components/Button/SignatureButton.vue";
import DrivingLessonsEducationRow from "@/views/Student/Info/DrivingLessonsEducationRow.vue";
import StudentEducationBKFEducationRow from "@/views/Student/Info/StudentEducationBKFEducationRow.vue";
import { CLASSIC_PAY, LIVE_PAY, FLASH_PAY } from "@/constants/PaymentWorkflow";
import { CERTIFICATE_TYPES } from "@/constants/CertificateTypes";
import { $axios } from "@/utils/axios";
import { AxiosResponse } from "axios";
import Datepicker from "@/components/Datepicker.vue";
import DeleteEducationModal from "@/components/Education/DeleteEducationModal.vue";
import DeleteEducationSecondModal from "@/components/Education/DeleteEducationSecondModal.vue";
import { checkFileSizeBeforeUpload } from "@/helper/files";
import { UploadConfiguration } from "@/enums/UploadConfiguration";
import ProofOfTrainingDocumentPreview from "@/views/Student/Info/ProofOfTrainingDocumentPreview.vue";
import { formatStudentName } from "@/utils/NameUtil";
import LicenseClassRequestMixin from "@/mixins/Request/LicenseClassRequestMixin";
import RegionSpecificDocumentRequestMixin from "@/mixins/Request/RegionSpecificDocumentRequestMixin";
import StudentEducationRequestMixin from "@/mixins/Request/StudentEducationRequestMixin";
import ProofOfTrainingRequestMixin from "@/mixins/Request/ProofOfTrainingRequestMixin";
import { IExamAndEducation } from "@/interfaces/Exam/IExamAndEducation";
import { PropType } from "vue";
import { IStudentEducation } from "@/interfaces/Student/IStudentEducation";
import sortBy from "lodash/sortBy";
import EducationContractDocumentPreview from "@/views/Student/Info/EducationContractDocumentPreview.vue";
import EducationContractRequestMixin from "@/mixins/Request/EducationContractRequestMixin";

const ModalModule = namespace("modal");

@Component({
  components: {
    DeleteEducationSecondModal,
    DeleteEducationModal,
    DrivingLessonsEducationRow,
    StudentEducationBKFEducationRow,
    SignatureButton,
    SignTrainingContract,
    SignTrainingContractModal,
    PrintButton,
    ArchiveButton,
    QrcodeButton,
    FscModal,
    SaveButton,
    AbortButton,
    Datepicker,
    ProofOfTrainingDocumentPreview,
    EducationContractDocumentPreview,
  },
})
export default class TrainingContract extends mixins(
  TrainingContractRequestMixin,
  LicenseClassRequestMixin,
  RegionSpecificDocumentRequestMixin,
  StudentEducationRequestMixin,
  ProofOfTrainingRequestMixin,
  EducationContractRequestMixin
) {
  public name = "TrainingContract";

  @Prop({ type: Array, required: true })
  public examAndEducationList!: Array<IExamAndEducation>;

  @Prop({ type: Boolean, default: () => false })
  public disabled!: boolean;

  @Prop({ required: true })
  public selectedStudent!: any;

  @Prop({ type: Boolean, required: false, default: () => true })
  public showActions!: boolean;

  @Prop({ type: Array as PropType<Array<IStudentEducation>>, default: () => [], required: true })
  public studentEducationList!: Array<IStudentEducation>;

  @ModalModule.Action("setStatus")
  private setModalStatus: any;

  @ModalModule.Action("setContent")
  private setModalContent: any;

  public activeEducation = 0;

  private studentQrCode = "";

  public radioSelected: any = [];
  public certificateOptions: any = [];
  public get certificateOptionsComputed() {
    return sortBy(this.certificateOptions, ["index"]);
  }
  public printDownloadDates: Array<string> = [];
  public selectedPrintDownloadDates: Array<string> = [];
  public datesLoading = false;

  public selectedEducationObject: any = "";
  public paymentTypeLivePay = LIVE_PAY;
  public paymentTypeClassicPay = CLASSIC_PAY;
  public paymentTypeFlashPay = FLASH_PAY;
  public signedStatusUpdated = { key: 0, value: false };

  public opacityClass = `m-0 pt-2`;
  public tabClass = `fsc-tabs`;

  public preSelectRadioSelected = "";
  public preSelectOptions = [
    {
      id: 1,
      text: "Ausbildungsvertag",
      value: "Ausbildungsvertag",
    },
    {
      id: 2,
      text: "Ausbildung bestätigt",
      value: "Ausbildung bestätigt",
    },
  ];

  public documentDate = "";
  public documentDateShow = false;

  public deleteEducationModalId = "delete-education-modal-id";
  public educationContractModalId = "education-contract-modal-id";
  public deleteEducationSecondModalId = "delete-education-second-modal-id";
  public fileSizeInvalid = false;
  public firstArchivePreventMessageVisible = true;
  public proofOfTrainingModalId = `${uuidv4()}-poof-of-training-preview`;
  public previousSelectedStudentId: any = null;
  public documentSelectPopover = `${uuidv4()}-documentSelectPopover`;
  public preselectModal = `${uuidv4()}-preselectModal`;

  private documentPreviewIsLoading = false;
  public sourceContract: any = "";

  private get documentPreviewLoading() {
    return this.documentPreviewIsLoading || this.regionSpecificDocumentsLoading || this.relatedDocumentsLoading;
  }

  public get initialDate(): any {
    return moment().format("YYYY-MM-DD");
  }

  public get paymentWorkflow() {
    return this.studentEducationList?.length > 0
      ? this.studentEducationList.map((education: IStudentEducation) => ({
          status: education.activeLivePayMandate !== null && education.activeLivePayMandate,
          paymentWorkflow: education.paymentWorkflow.id,
        }))
      : [];
  }

  private get activePaymentWorkflow() {
    return this.paymentWorkflow?.[this.activeEducation];
  }

  public get signTrainingContractModalId() {
    return `${uuidv4()}-sign-training-contract-modal-id`;
  }

  public onAbortRadioSelect(): void {
    this.$bvModal.hide(this.documentSelectPopover);
  }

  public onCancelDocumentSelectPopover(): void {
    this.radioSelected = "";
    // Abort request before loading if clicked X button
    this.licenseClassCancelableRequest.cancelPreviousRequest();
    this.regionSpecificDocumentCancelableRequest.cancelPreviousRequest();
  }

  public onRadioSelectSubmit() {
    //eslint-disable-next-line no-prototype-builtins
    if (this.radioSelected && this.radioSelected.type === "Leistungsnachweis") {
      this.onServiceReportPreview("education-events/bookedServicesReportPdf", this.selectedEducationObject);
      this.radioSelected = "";
    }
    //eslint-disable-next-line no-prototype-builtins
    else if (this.radioSelected && this.radioSelected.type === "Leistungsvorschau") {
      this.onServiceReportPreview("education-events/plannedServicesReportPdf", this.selectedEducationObject);
      this.radioSelected = "";
    }
    //eslint-disable-next-line no-prototype-builtins
    else if (this.radioSelected && this.radioSelected.hasOwnProperty("id")) {
      this.onRegionSpecificDocumentsPreview("region-specific-documents", this.radioSelected);
      this.radioSelected = "";
    } else {
      switch (this.radioSelected) {
        case "training-contract":
          this.onContractPreview();
          this.radioSelected = "";
          break;
        case "proof-of-training":
          this.radioSelected = "";
          this.$bvModal.hide(this.documentSelectPopover);
          this.$bvModal.show(this.proofOfTrainingModalId);
          break;
        case "bkf-certificate":
          this.onBKFCertificatePreview();
          this.radioSelected = "";
          this.documentDate = "";
          break;
        default:
          this.onAllCertificatesPreview(this.radioSelected);
          this.radioSelected = "";
          this.documentDate = "";
      }
    }
  }

  protected async onContractPreview(): Promise<void> {
    if (this.documentDate && this.documentDate.length > 0) {
      this.documentPreviewIsLoading = true;
      this.sourceContract = await getFile(
        {
          method: "POST",
          url: `training-contract/preview-standard?studentEducationId=${this.examAndEducationList[this.activeEducation].studentEducationId}&issueDate=${
            this.documentDate
          }`,
        },
        false,
        true,
        false
      )
        .then((res: any) => {
          this.$bvModal.hide(this.documentSelectPopover);
          if (res) {
            this.$bvModal.show(this.educationContractModalId);
          }
          return res;
        })
        .finally(() => {
          this.documentPreviewIsLoading = false;
        });
    } else {
      this.$toasted.error("You must select date!");
    }
  }

  protected async onProofOfTrainingCreate(): Promise<void> {
    this.$bvModal.hide(this.proofOfTrainingModalId);
    const id = this.examAndEducationList[this.activeEducation].studentEducationId;
    const date = this.documentDate;
    await this.createProofOfTraining(id, date);
    this.documentDate = "";
    this.$root.$emit("refresh-student-info");
  }

  protected onProofOfTrainingCancel() {
    this.$bvModal.hide(this.proofOfTrainingModalId);
    this.documentDate = "";
  }

  protected async onEducationContractCreate() {
    this.$bvModal.hide(this.educationContractModalId);
    const id = this.examAndEducationList[this.activeEducation].studentEducationId;
    const date = this.documentDate;
    await this.createEducationContract(id, date);
    this.documentDate = "";
  }
  protected onEducationContractCancel() {
    this.$bvModal.hide(this.educationContractModalId);
    this.documentDate = "";
  }

  protected async onBKFCertificatePreview(): Promise<void> {
    this.documentPreviewIsLoading = true;
    await getFile({
      method: "POST",
      url: `bkf-certificate`,
      data: {
        studentEducationId: this.examAndEducationList[this.activeEducation].studentEducationId,
        dates: this.selectedPrintDownloadDates,
        issueDate: this.documentDate ? this.documentDate : "",
      },
    })
      .then(() => {
        this.$bvModal.hide(this.documentSelectPopover);
      })
      .finally(() => {
        this.documentPreviewIsLoading = false;
      });
  }

  protected async onAllCertificatesPreview(url: any): Promise<void> {
    if (this.documentDate && this.documentDate.length > 0) {
      this.documentPreviewIsLoading = true;
      await getFile({
        method: "GET",
        url: `${url}?studentEducationId=${this.examAndEducationList[this.activeEducation].studentEducationId}&issueDate=${this.documentDate}`,
      })
        .then(() => {
          this.$bvModal.hide(this.documentSelectPopover);
        })
        .finally(() => {
          this.documentPreviewIsLoading = false;
        });
    } else {
      this.$toasted.error("You must select date!");
    }
  }

  protected async onRegionSpecificDocumentsPreview(url: any, document: any): Promise<void> {
    const documentId = document ? document.id : null;
    this.documentPreviewIsLoading = true;
    await getFile({
      method: "GET",
      url: `${url}/generate-document?documentId=${documentId}&studentEducationId=${this.examAndEducationList[this.activeEducation].studentEducationId}`,
    })
      .then(() => {
        this.$bvModal.hide(this.documentSelectPopover);
      })
      .finally(() => {
        this.documentPreviewIsLoading = false;
      });
  }

  protected async onServiceReportPreview(url: any, education: any): Promise<void> {
    const educationId = education ? education.studentEducationId : null;
    this.documentPreviewIsLoading = true;
    await getFile({
      method: "GET",
      url: `${url}/${educationId}`,
    })
      .then(() => {
        this.$bvModal.hide(this.documentSelectPopover);
      })
      .finally(() => {
        this.documentPreviewIsLoading = false;
      });
  }

  public onDocumentPreviewSelect(): void {
    this.$bvModal.show(this.documentSelectPopover);
    this.certificateOptions = [];

    if (this.examAndEducationList[this.activeEducation]) {
      const licenseClass = this.examAndEducationList[this.activeEducation].licenseClass;

      const fetchRelatedDoc = async () => {
        await this.getRelatedDocuments(licenseClass);
        let index = 0;
        if (this.relatedDocuments) {
          for (const key in this.relatedDocuments) {
            CERTIFICATE_TYPES.map((certificate: any) => {
              if (certificate.type === key) {
                this.certificateOptions.push({ index: index, ...certificate });
                index++;
              }
            });
          }
        }
      };

      const fetchRegionSpecifDoc = async () => {
        await this.findAllRegionSpecificDocuments();
        let index = 10000;
        if (this.regionSpecificDocuments.length) {
          for (const document of this.regionSpecificDocuments) {
            const reformatted = {
              index: index,
              id: document.id,
              text: document.name,
              value: {
                type: "specific",
                id: document.id,
              },
            };
            this.certificateOptions.push(reformatted);
            index++;
          }
        }
      };

      fetchRelatedDoc();
      fetchRegionSpecifDoc();
    }

    this.certificateOptions.push({
      index: 99998,
      id: this.selectedEducationObject ? this.selectedEducationObject.studentEducationId : null,
      text: "Leistungsnachweis",
      value: {
        id: this.selectedEducationObject ? this.selectedEducationObject.studentEducationId : null,
        type: "Leistungsnachweis",
      },
    });

    this.certificateOptions.push({
      index: 99999,
      id: this.selectedEducationObject ? this.selectedEducationObject.studentEducationId : null,
      text: "Leistungsvorschau",
      value: {
        id: this.selectedEducationObject ? this.selectedEducationObject.studentEducationId : null,
        type: "Leistungsvorschau",
      },
    });
  }

  @Watch("radioSelected", { immediate: true, deep: true })
  public async onLoadPrintDates() {
    if (this.radioSelected.length > 0 && this.radioSelected !== "bkf-certificate") {
      this.documentDate = this.initialDate;
      this.documentDateShow = true;
      // eslint-disable-next-line no-prototype-builtins
    } else if (this.radioSelected && this.radioSelected.hasOwnProperty("id")) {
      this.documentDateShow = false;
    }
    if (this.radioSelected !== "bkf-certificate") return;
    this.datesLoading = true;
    const url = "bkf-certificate/get-date-options/" + this.examAndEducationList[this.activeEducation].studentEducationId;
    await $axios
      .get(url)
      .then(({ data }: AxiosResponse) => {
        this.documentDate = this.initialDate;
        this.documentDateShow = true;
        this.printDownloadDates = data;
        this.datesLoading = false;
      })
      .catch((e) => {
        console.error(e);
        this.datesLoading = false;
      });
  }

  public preselectModalShow() {
    this.$bvModal.show(this.preselectModal);
  }

  public preSelectSubmit() {
    this.signContract();
    this.$bvModal.hide(this.preselectModal);
  }

  public preSelectAbort() {
    this.preSelectRadioSelected = "";
    this.$bvModal.hide(this.preselectModal);
  }

  //MAIN SIGN CONTRACT LOGIC
  protected async signContract(): Promise<void> {
    if (this.disabled) return;
    const studentEducationId = this.activeExamAndEducation?.studentEducationId;

    if (studentEducationId) {
      await this.hasSignedDocument(studentEducationId);
      if (this.documentSignedResponse && this.preSelectRadioSelected === "Ausbildungsvertag") {
        this.$toasted.error(String(this.$t(`report.contract_signed`)));
        return;
      } else {
        await this.addonsCheckboxText(studentEducationId);
        await this.gdprUrlCheckboxText(studentEducationId);
        this.$bvModal.show(this.signTrainingContractModalId);
      }
    }
  }

  public changeTabEducation(index: number, education: any) {
    if (education && education.archivedEducation) {
      this.opacityClass = `m-0 pt-2 opc`;
      this.tabClass = `opc-tabs`;
    } else {
      this.opacityClass = `m-0 pt-2`;
      this.tabClass = `fsc-tabs`;
    }
    this.activeEducation = index;
  }

  protected async onSignTrainingContract(cbOk: () => void): Promise<void> {
    const { studentIsEmpty, studentSignFile, parentIsEmpty, parentSignFile, termsAndConditions, confirmChargeableAddons, confirmGDPRs, typeSignature } = (
      this.$refs.signTrainingContract as SignTrainingContract
    ).getData();

    const studentFormData = new FormData();
    const parentFormData = new FormData();
    const studentEducationId = this.examAndEducationList[this.activeEducation]?.studentEducationId;
    if (!studentEducationId) {
      return;
    }
    const filename = `${studentEducationId}.png`;

    if (!termsAndConditions || !confirmChargeableAddons || !confirmGDPRs) {
      if (typeSignature === "Ausbildungsvertag") {
        return;
      } else if (typeSignature === "Ausbildung bestätigt") {
        if (!studentIsEmpty && studentSignFile) {
          studentFormData.append("file", dataURLtoFile(studentSignFile, filename));
          await this.uploadProofOfTrainingStudentSignature(studentEducationId, studentFormData);
          // await this.createProofOfTrainingSigned(studentEducationId);
        }
        this.$root.$emit("refresh-student-info");
      }
    } else {
      if (this.isAdult) {
        if (!studentIsEmpty && studentSignFile) {
          this.fileSizeInvalid = !checkFileSizeBeforeUpload(studentSignFile, UploadConfiguration.SIZE_CONFIGURATION);
          if (this.fileSizeInvalid) {
            return;
          }
          studentFormData.append("file", dataURLtoFile(studentSignFile, filename));
          await this.uploadStudentSignature(studentEducationId, studentFormData);
          await this.createSigned(studentEducationId);
        }
      } else {
        if (!studentIsEmpty && studentSignFile && !parentIsEmpty && parentSignFile) {
          const studentFileSizeInvalid = !checkFileSizeBeforeUpload(studentSignFile, UploadConfiguration.SIZE_CONFIGURATION);
          const parentFileSizeInvalid = !checkFileSizeBeforeUpload(studentSignFile, UploadConfiguration.SIZE_CONFIGURATION);

          this.fileSizeInvalid = studentFileSizeInvalid || parentFileSizeInvalid;
          if (this.fileSizeInvalid) {
            return;
          }

          studentFormData.append("file", dataURLtoFile(studentSignFile, filename));
          parentFormData.append("file", dataURLtoFile(parentSignFile, filename));
          await this.uploadStudentSignature(studentEducationId, studentFormData);

          await this.uploadParentSignature(studentEducationId, parentFormData);
          await this.createSigned(studentEducationId);
        }
      }
    }

    if (this.signedSuccess) {
      cbOk();
      this.fileSizeInvalid = false;
      await this.setModalStatus(true);
      await this.setModalContent(this.signedResponse);
      // await this.findExamAndEducationByStudent(this.selectedStudent.id).then((data: any) => {
      //   this.signedStatusUpdated.key = this.activeEducation;
      //   this.signedStatusUpdated.value = data[this.activeEducation].signed;
      // });
    }

    if (this.signedProofSuccess) {
      this.$bvModal.hide(this.signTrainingContractModalId);
    }
  }

  private get isAdult(): boolean {
    if (!this.selectedStudent) return false;
    return moment().diff(this.selectedStudent.birthDate, "years") >= 18;
  }

  public onCancel() {
    this.fileSizeInvalid = false;
  }

  @Watch("examAndEducationList", { deep: true, immediate: true })
  protected onExamAndEducationListChange(educations: Array<IExamAndEducation>): void {
    if (educations.length === 0) return;

    if (this.previousSelectedStudentId === null || this.previousSelectedStudentId !== this.selectedStudent.id) {
      this.previousSelectedStudentId = this.selectedStudent ? this.selectedStudent.id : null;
      if (educations.length > 0) {
        this.selectedEducationObject = educations[0];
      }
      if (educations[0] && educations[0]?.archivedEducation) {
        this.opacityClass = `m-0 pt-2 opc`;
        this.tabClass = `opc-tabs`;
      } else {
        this.opacityClass = `m-0 pt-2`;
        this.tabClass = `fsc-tabs`;
      }
      this.activeEducation = 0;
    } else if (this.previousSelectedStudentId === this.selectedStudent.id) {
      if (educations.length > 0) {
        this.selectedEducationObject = educations[this.activeEducation];
      }
      if (educations[this.activeEducation] && educations[this.activeEducation]?.archivedEducation) {
        this.opacityClass = `m-0 pt-2 opc`;
        this.tabClass = `opc-tabs`;
      } else {
        this.opacityClass = `m-0 pt-2`;
        this.tabClass = `fsc-tabs`;
      }
    }
  }

  protected get activeExamAndEducation() {
    return this.examAndEducationList[this.activeEducation];
  }

  public getStudentQrCode(): void {
    getFile(
      {
        method: "GET",
        url: `/students/onboarding-qr-code?studentId=${this.selectedStudent.id}`,
      },
      false
    ).then((value) => {
      this.studentQrCode = value;
      this.$bvModal.show(this.qrCodePopoverId);
    });
  }

  public get grantingTypeName() {
    return this.activeExamAndEducation?.grantingTypeName || "";
  }

  public get registrationDate() {
    return this.activeExamAndEducation?.registrationDate || "";
  }

  public get completedDTO() {
    return this.activeExamAndEducation?.completedDTO || ({} as ICompletedDTO);
  }

  public get plannedLessonsDTO() {
    return this.activeExamAndEducation?.plannedLessonsDTO || ({} as IPlannedLessonsDTO);
  }

  public get licenseClassB197() {
    return this.activeExamAndEducation?.licenseClass === "B197";
  }

  public get requirementsDTO() {
    return this.activeExamAndEducation?.requirementsDTO || ({} as IRequirementsDTO);
  }

  public get externalServicesDTO() {
    return this.activeExamAndEducation?.externalServicesDTO || ({} as IRequirementsDTO);
  }

  public get includesBasicTheory() {
    return this.activeExamAndEducation?.includesBasicTheory;
  }

  public get isGQSorGQD() {
    return this.activeExamAndEducation?.licenseClass === "GQC" || this.activeExamAndEducation?.licenseClass === "GQD";
  }

  public get isASF() {
    return this.activeExamAndEducation?.licenseClass === "ASF";
  }

  public get instructorList() {
    return this.activeExamAndEducation?.instructorList || [];
  }

  public onArchiveModalShow() {
    if (this.selectedStudent.archived) {
      this.$toasted.show(String(this.$t("messages.extract_student_from_archive")), { type: "info" });
    } else {
      this.firstArchivePreventMessageVisible = true;
      this.$bvModal.show("modalArchivePopover");
    }
  }

  public onArchiveEducation() {
    if (this.firstArchivePreventMessageVisible && this.selectedEducationObject.archivedEducation === false) {
      this.firstArchivePreventMessageVisible = false;
      return;
    }
    this.firstArchivePreventMessageVisible = true;
    this.$bvModal.hide("modalArchivePopover");
    this.$emit("archive-education", this.selectedEducationObject);
  }

  public onArchiveEducationAbort() {
    this.$bvModal.hide("modalArchivePopover");
    this.firstArchivePreventMessageVisible = true;
  }

  @Watch("activeEducation", { immediate: true, deep: true })
  private onActiveEducationChange(statusTab: any): void {
    this.selectedPrintDownloadDates = [];
    this.radioSelected = "";
    this.selectedEducationObject = this.examAndEducationList[statusTab];
  }

  public get qrCodePopoverId() {
    return uuidv4();
  }

  public get hasSignedContract() {
    const signed = this.signedStatusUpdated.key === this.activeEducation ? this.signedStatusUpdated.value : false;
    return this.activeExamAndEducation?.signed || signed;
  }

  public get hasSignedProofOfTraining() {
    return this.activeExamAndEducation?.signatureForProofOfTrainingCaptured || false;
  }

  public get hasCreatedProofOfTraining() {
    return this.activeExamAndEducation?.proofOfTrainingCreated || false;
  }

  public get signatureLoading() {
    return this.uploadStudentSignatureLoading || this.uploadParentSignatureLoading || this.signedLoading;
  }

  public onDeleteEducation() {
    this.$bvModal.show(this.deleteEducationModalId);
  }

  public confirmDeleteEducation() {
    this.$bvModal.show(this.deleteEducationSecondModalId);
  }

  public get studentName() {
    if (!this.selectedStudent) return "";
    return formatStudentName(this.selectedStudent.firstName, this.selectedStudent.lastName);
  }

  public get activeEducationLicenseClass() {
    return this.activeExamAndEducation?.licenseClass;
  }

  public async confirmDeleteEducationSecond() {
    const studentEducationId = this.activeExamAndEducation?.studentEducationId;
    if (studentEducationId) {
      await this.delStudentEducation(studentEducationId);
    }
    return this.delStudentEducationSuccess;
  }

  public get studentEducationBKFEducationDTO() {
    return this.activeExamAndEducation?.studentEducationBKFEducationDTO || [];
  }

  public get isBKFEducation() {
    return this.activeExamAndEducation?.bkfEducation;
  }
}
